@font-face {
font-family: '__geist_69a845';
src: url(/_next/static/media/6bb9846031bb03b3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__geist_69a845';
src: url(/_next/static/media/d1eb6e250d92a46a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__geist_69a845';
src: url(/_next/static/media/03e99ac36af9610f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__geist_Fallback_69a845';src: local("Arial");ascent-override: 85.60%;descent-override: 20.47%;line-gap-override: 9.30%;size-adjust: 107.48%
}.__className_69a845 {font-family: '__geist_69a845', '__geist_Fallback_69a845'
}

